import React, {useState, useEffect} from "react";
import {NavLink} from 'react-router-dom';

export const NavBar = () => {

  const [openMenuMobile, setOpenMenuMobile] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  useEffect(() => {
    window.addEventListener("resize", () => {
      setWindowWidth(window.innerWidth);
    });
  }, []);

  // const contact = () => {
  //   const element = document.getElementById('contactFooter');
  //   if (element) {
  //     // element.scrollIntoView({ behavior: 'smooth' });
  //     window.scrollTo(0, element.offsetTop - 82);
  //   }
  // };

  return (
    <div className='cont-nav'>
      <div className="nav-container container">
        <div className="logo">
          <NavLink onClick={() => setOpenMenuMobile(false)} to='/accueil'>
            <img src={require('./../images/logo-small.png')} alt="Logo Sarl Demat" />
          </NavLink>
        </div>
        <div className="menu">
          <div className={`bgMenuMobile ${openMenuMobile ? "open" : ""}`} onClick={() => setOpenMenuMobile(false)}>
            <div className="closeButtonMenuMobile" onClick={() => setOpenMenuMobile(false)}>
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none"><path fill="#fff" d="M.397.554.47.47a.75.75 0 0 1 .976-.073L1.53.47 8 6.939l6.47-6.47a.75.75 0 1 1 1.06 1.061L9.061 8l6.47 6.47a.75.75 0 0 1 .072.976l-.073.084a.75.75 0 0 1-.976.073l-.084-.073L8 9.061l-6.47 6.47A.75.75 0 0 1 .47 14.47L6.939 8 .469 1.53A.75.75 0 0 1 .398.554L.47.47.397.554Z"/></svg>
            </div>
          </div>
          <div className="buttonMenuMobile" onClick={() => setOpenMenuMobile(true)}>
            <svg xmlns="http://www.w3.org/2000/svg" width="21" height="15" fill="none"><path fill="#002E5F" d="M.753 13h18.5a.75.75 0 0 1 .102 1.493l-.102.007H.753a.75.75 0 0 1-.102-1.493L.753 13h18.5-18.5Zm0-6.497h18.5a.75.75 0 0 1 .102 1.493l-.102.007H.753A.75.75 0 0 1 .65 6.51l.102-.007h18.5-18.5ZM.752.003h18.5a.75.75 0 0 1 .102 1.493l-.102.007H.752A.75.75 0 0 1 .65.01L.752.003h18.5-18.5Z"/></svg>
          </div>
          <ul className={`${windowWidth <= 992 ? "mobile" : ""} ${openMenuMobile ? "open" : ""}`}>
            <li><NavLink onClick={() => setOpenMenuMobile(false)} className={({ isActive }) => isActive ? 'active' : ''}  to='/accueil'>Accueil</NavLink></li>
            <li><NavLink onClick={() => setOpenMenuMobile(false)} className={({ isActive }) => isActive ? 'active' : ''}  to='/fours-a-pizza-morello-forni'>Fours à pizza</NavLink></li>
            <li><NavLink onClick={() => setOpenMenuMobile(false)} className={({ isActive }) => isActive ? 'active' : ''} to='/la-pizzarella'>La Pizzarella</NavLink></li>
            <li><NavLink onClick={() => setOpenMenuMobile(false)} className={({ isActive }) => isActive ? 'active' : ''} to='/diviseuses-bouleuses'>Diviseuses bouleuses</NavLink></li>
            {/* <li><NavLink onClick={() => setOpenMenuMobile(false)} className={({ isActive }) => isActive ? 'active' : ''} to='/les-petrins'>Les Petrins</NavLink></li> */}
            {/* <li><NavLink onClick={() => {setOpenMenuMobile(false); contact();}} className={({ isActive }) => !isActive ? 'active' : ''}>Contact</NavLink></li> */}
            <li><NavLink onClick={() => setOpenMenuMobile(false)} className={({ isActive }) => isActive ? 'active' : ''} to='/contact'>Contact</NavLink></li>
          </ul>
        </div>
      </div>
    </div>
  );

};
import React from 'react';
import {NavBar} from './components/NavBar';
import {Home} from "./pages/Home";
import {FourMorello} from "./pages/FoursMorello";
import {FourMorelloDetails} from "./pages/FoursMorelloDetails";
import {Pizzarella} from "./pages/Pizzarella";
import {Petrins} from "./pages/Petrins";
import {Diviseuses} from "./pages/Diviseuses";
import {Contacts} from "./pages/Contacts";
import {TermsConditions} from "./pages/TermsConditions";
// import {ContactFooter} from "./components/ContactFooter";
import {Footer} from "./components/Footer";
import {Separator} from './components/Separator';
import {Loader} from './helpers/Loader/Loader';
// import {ScrollToTop} from "./components/ScrollToTop";
import './App.css';
import { Route, Routes, BrowserRouter, Navigate } from "react-router-dom";


export const App = (props) => {

    const NotFoundPage = () => {
      return (
        <React.Fragment>
          <Loader />
          {/* <Separator /> */}
          <div className="page404 container">
            <h1>404</h1>
            <div>
              <span>La page que vous avez demandée n'existe pas.</span>
            </div>
          </div>
        </React.Fragment>
      );
    };

    return (
      <div className='containerApp'>
        <BrowserRouter basename={'/'}>
          <NavBar />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/home" element={<Home />} />
            <Route path="/accueil" element={<Home />} />
            <Route path="/fours-a-pizza-morello-forni" element={<FourMorello />} />
            <Route path="/fours-a-pizza-morello-forni/:title" element={<FourMorelloDetails />} />
            {/* <Route path="fours-morello-details" render={(props) => <FourMorelloDetails  {...props} />} /> */}
            <Route path="/la-pizzarella" element={<Pizzarella />} />
            <Route path="/les-petrins" element={<Petrins />} />
            <Route path="/diviseuses-bouleuses" element={<Diviseuses />} />
            <Route path="/contact" element={<Contacts />} />
            <Route path="/terms-conditions" element={<TermsConditions />} />
            <Route path="*" element={<Navigate replace to="/404" />} />
            <Route path="/404" element={<NotFoundPage />} />
          </Routes>
          {/* <Separator space={true} />
          <ContactFooter /> */}
          {/* <Separator space={false} /> */}
          <Footer />
          {/* <ScrollToTop /> */}
        </BrowserRouter>
      </div>
    );
  
}


import React, { useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { Loader } from '../helpers/Loader/Loader';
import { Separator } from './../components/Separator';
import { Helmet, HelmetData } from 'react-helmet-async';
import './../App.css';

export const Diviseuses = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const contact = () => {
    const element = document.getElementById('contactFooter');
    if (element) {
      // element.scrollIntoView({ behavior: 'smooth' });
      window.scrollTo(0, element.offsetTop - 92);
    }
  };

  const helmetData = new HelmetData({});

  return (
    <React.Fragment>
      <Helmet helmetData={helmetData} >
        <title>Diviseuses Bouleuses - Fours Demat</title>
        <meta name="description" content="La diviseuse bouleuse est une machine rapide et fiable qui permet de d&eacute;couper un morceau de p&acirc;te crue, en petits p&acirc;tons, et successivement, gr&acirc;ce &agrave; un plateau oscillant, de bouler chaque morceau, de fa&ccedil;on &agrave; obtenir des boules de poids et forme identiques, tout ceci en quelques secondes, sans alt&eacute;rer les caract&eacute;ristiques du produit." />
        <meta property="og:title" content="Diviseuses Bouleuses"/>
        <meta property="og:description" content="La diviseuse bouleuse est une machine rapide et fiable qui permet de d&eacute;couper un morceau de p&acirc;te crue, en petits p&acirc;tons, et successivement, gr&acirc;ce &agrave; un plateau oscillant, de bouler chaque morceau, de fa&ccedil;on &agrave; obtenir des boules de poids et forme identiques, tout ceci en quelques secondes, sans alt&eacute;rer les caract&eacute;ristiques du produit." />
        <meta property="og:type" content="article" />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:image" content={require('./../images/vitella-automatique.jpg')} />
        <meta property="og:site_name" content="Fours Demat" />
        <meta property="og:section" content="Diviseuses Bouleuses" />
        <link rel="canonical" href="https://www.foursdemat.com/diviseuses-bouleuses" />
      </Helmet>
      <Loader />
      {/* <Separator /> */}
      <div className='vitella'>
        <div className='bannerContact' style={{marginBottom: 30}}>
          <div className='container'>
            <div className='row'>
              <h1>Vitella Diviseuses Bouleuses</h1>
              <p className='morelloDescription'>
                Machine à manipulation hydraulique, avec 2 cylindres pour la phase de pressage, un cylindre pour le découpage et un pour le boulage. 
                {/* <br />
                Tableau de commande avec possibilité de régler le temps de pressage, de boulage et d'ouverture de la chambre de formage. 
                <br />
                Bouton pour le nettoyage des couteaux. Possibilité de mémoriser jusqu'à 9 programmes différents. 
                <br />
                La machine est fournie avec 3 plateaux. */}
              </p>
            </div>
          </div>
        </div>
        <div className='container'>
          <div className='row'>
          <div className='col-sm-12 col-md-12 col-lg-12 col-xl-12'>
              <div className='description'>
                <div>
                  <span><strong>Cycle de travail</strong></span>
                </div>
                <div>
                  <ol>
                    <li>insérer le plateau manuellement</li>
                    <li>fermer le dispositif de protection antérieur</li>
                    <li>programmer le numéro du programme - programmer les temps de travail (pressage, boulage et chambre)</li>
                    <li>appuyer sur la touche Start</li>
                    <li>la machine presse, découpe et boule en automatique</li>
                    <li>ouvrir le dispositif de protection antérieur</li>
                    <li>enlever le plateau avec les boules formées.</li>
                  </ol>
                </div>
                <div>
                  <p>
                    <strong>T1-Temps de pressage:</strong> c'est le temps qui sert pour distribuer la pâte à l'intérieur de la cuve de façon uniforme, de manière à ce que dans la phase de découpage chaque morceau soit du même poids et du même volume.
                    Résultat : si les morceaux externes étaient d'un poids supérieur au poids des morceaux à l'intérieur, il faudrait diminuer le temps, si au contraire le poids était inférieur, il faudrait augmenter le temps.
                    Conseil : mettre la pâte le plus au centre possible du plateau et aplatir le morceau de pâte avec les mains. Laisser reposer la pâte une dizaine de minutes avant de la travailler.
                  </p>
                </div>
                <div>
                  <p>
                    <strong>T2-Temps de boulage:</strong> c'est le temps qui sert au plateau oscillant pour former les boules après la phase de découpage.
                    Résultat : si les boules étaient déformées, il faudrait diminuer le temps, si au contraire elles n'étaient pas formées, il faudrait augmenter le temps, toujours (de pair avec le T3) en tenant compte du T3.
                  </p>
                </div>
                <div>
                  <p>
                    <strong>T3-Temps d'ouverture ou de fermeture de la chambre de formage:</strong> c'est le temps qui permettra d'ouvrir ou de fermer la chambre de formage. Il sert pour créer le bon espace dans la chambre qui, lors de la phase de boulage, permettra d'obtenir une boule bien formée.
                    Résultat: Si la boule était déformée, il faudrait augmenter le temps (la chambre s'ouvrirait donc davantage), si au contraire elle ne se formait pas, il faudrait diminuer le temps, (la chambre se fermerait).
                  </p>
                </div>
              </div>
            </div>
            <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 article">
              <div className='contBoxPizzarella'>
                <div className='row'>
                  <div className="col-sm-12 col-md-4 col-lg-4 col-xl-2">
                    <div className='image'>
                    <img src={require('./../images/vitella-automatique.jpg')} alt="Vitella Diviseuse Bouleuse" />
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-8 col-lg-8 col-xl-10 description">
                    <div className='title'>
                      <span>Diviseuse Bouleuse automatique</span>
                    </div>
                    <div>
                      <span>Les diviseuses bouleuses automatiques sont des machines qui permettent de découper un morceau de pâte crue, en petits pâtons, et successivement, grâce à un plateau oscillant, de bouler chaque morceau, de façon à obtenir des boules de poids et forme identiques, tout ceci en quelques secondes!</span>
                    </div>
                    <div className='documentation'>
                      <div>
                        <a 
                          target={"blank"} 
                          href={`https://www.youtube.com/embed/9ujC0ddKYrs?html5=1&amp;enablejsapi=1&amp;autoplay=0&amp;rel=0&amp;showinfo=0&amp;modestbranding=1&amp;controls=1&amp;autohide=0&amp;vq=large`} 
                          title="Regarder la vidéo Vitella"
                        >
                          <span>Regarder la vidéo</span>
                          <span>
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none"><g clip-path="url(#a)"><path fill="#2d4fb3" d="M19.6 5.15a2.51 2.51 0 0 0-1.77-1.78c-1.56-.42-7.81-.42-7.81-.42s-6.25 0-7.81.42A2.51 2.51 0 0 0 .44 5.15C.02 6.72.02 10 .02 10s0 3.27.42 4.85a2.51 2.51 0 0 0 1.77 1.77c1.56.43 7.81.43 7.81.43s6.25 0 7.81-.43a2.51 2.51 0 0 0 1.77-1.77c.42-1.58.42-4.85.42-4.85s0-3.28-.42-4.85Z"></path><path fill="#FEFEFE" d="M7.97 12.97V7.03L13.2 10l-5.23 2.97Z"></path></g><defs><clipPath id="a"><path fill="#fff" d="M0 0h20v20H0z"></path></clipPath></defs></svg>
                          </span>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 article">
              <div className='contBoxPizzarella'>
                <div className='row'>
                  <div className="col-sm-12 col-md-4 col-lg-4 col-xl-2">
                    <div className='image'>
                    <img src={require('./../images/vitella-semi-automatique.jpg')} alt="Vitella Diviseuse Bouleuse" />
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-8 col-lg-8 col-xl-10 description">
                    <div className='title'>
                      <span>Diviseuse bouleuse semi-automatique</span>
                    </div>
                    <div>
                      <span>Les diviseuses bouleuses semi-automatiques sont des machines qui permettent de découper un morceau de pâte crue, en petits pâtons, et successivement, grâce à un plateau oscillant, de bouler chaque morceau, de façon à obtenir des boules de poids et forme identiques, tout ceci en quelques secondes!</span>
                    </div>
                    <div className='documentation'>
                      <div>
                        <a 
                          target={"blank"} 
                          href={`https://www.youtube.com/embed/9ujC0ddKYrs?html5=1&amp;enablejsapi=1&amp;autoplay=0&amp;rel=0&amp;showinfo=0&amp;modestbranding=1&amp;controls=1&amp;autohide=0&amp;vq=large`} 
                          title="Regarder la vidéo Vitella"
                        >
                          <span>Regarder la vidéo</span>
                          <span>
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none"><g clip-path="url(#a)"><path fill="#2d4fb3" d="M19.6 5.15a2.51 2.51 0 0 0-1.77-1.78c-1.56-.42-7.81-.42-7.81-.42s-6.25 0-7.81.42A2.51 2.51 0 0 0 .44 5.15C.02 6.72.02 10 .02 10s0 3.27.42 4.85a2.51 2.51 0 0 0 1.77 1.77c1.56.43 7.81.43 7.81.43s6.25 0 7.81-.43a2.51 2.51 0 0 0 1.77-1.77c.42-1.58.42-4.85.42-4.85s0-3.28-.42-4.85Z"></path><path fill="#FEFEFE" d="M7.97 12.97V7.03L13.2 10l-5.23 2.97Z"></path></g><defs><clipPath id="a"><path fill="#fff" d="M0 0h20v20H0z"></path></clipPath></defs></svg>
                          </span>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='container'>
          <div className='contactezNous'>
            <a 
              href={'/contact'} 
              title="Contactez-nous et nous serons heureux de vous fournir plus d'informations!"
            >
              Contactez-nous et nous serons heureux de vous fournir plus d'informations!
            </a>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
import React from "react";

export const Marketing = () => {
  return (
    <div className="contPrivacyMarketing">
      <p>
        que mes données à caractère personnel fassent l'objet d'un traitement par  <span style={{fontWeight: 500}}>Sarl Demat</span> à des fins de marketing telles que décrites au point "c" de la note d'information, sous format papier, automatisé ou électronique, et notamment par courrier postal ou électronique, par téléphone (par exemple : automates d'appel, SMS, MMS), par télécopie et par tout autre moyen (par exemple : sites web, applications mobiles).
      </p>
    </div>
  );
};
import React from "react";
import { HashLink } from 'react-router-hash-link';
import { fours } from "../articles/fours";

export const Fours = (p) => {

  const getMultipleRandom = (arr, num) => {
    const shuffled = [...arr].sort(() => 0.5 - Math.random());
    return shuffled.slice(0, num);
  };

  // const result = p?.id === undefined ? fours.filter(four => four.category === p?.category) : getMultipleRandom(fours.filter(four => (four.category === p?.category && four.id !== p?.id)), p?.articles);
  const getData = () => {
    if(p?.id === undefined){
      if(p?.random){
        return getMultipleRandom(fours, p?.articles);
      }else{
        return fours.filter(four => four.category === p?.category)
      }
    }else{
      return getMultipleRandom(fours.filter(four => (four.category === p?.category && four.id !== p?.id)), p?.articles);
    }
  };

  const result = getData();

  return (
    <div className='container articlesContainer'>
      <div className="row">
        <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <div className="row">
            {p?.category !== "" && p?.viewTag &&
              <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 ">
                <div className="headerTitle" id={p?.category}>
                  <h3>
                    {p?.id === undefined ? `Fours à pizza ${p?.category}` : `Autres modèles des fours à pizza ${p?.category}`}
                  </h3>
                </div>
              </div>
            }
            {result.slice(0, p?.articles !== 0 ? (0, p?.articles) : "").map((item, i) => {
              return (
                <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 article" key={i}>
                  <div className="row">
                    <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                      <div className="contBoxFour">
                        <div className="row">
                          <div className="col-sm-12 col-md-12 col-lg-6 col-xl-4">
                            <div className="image">
                              <img
                                src={require('./../images/forni/' + item.image)}
                                alt={item.title}
                                onClick={() => window.open('/fours-a-pizza-morello-forni/' + encodeURIComponent(item.title.trim()), "_self")}
                              />
                            </div>
                          </div>
                          <div className="col-sm-12 col-md-12 col-lg-6 col-xl-8">
                            <div className="box">
                              <div className="headerBox">
                                <div className="title">
                                  <span>Four à pizzas <b>{item.title === "LP" ? "L/LP" : item.title}</b></span>
                                </div>
                                <div className="description">
                                  <span>{item.shortDescription}</span>
                                </div>
                                <div className="details">
                                  {/* <div className="tagHeader">Energie</div> */}
                                  {item.four.length > 0 &&
                                    <div className="tags">
                                      <span>Four: {item.four}</span>
                                    </div>
                                  }
                                  {item.sole.length > 0 &&
                                    <div className="tags">
                                      <span>Sole: {item.sole}</span>
                                    </div>
                                  }
                                </div>
                              </div>
                              <div className="footerBox">
                                <div className="price">
                                  <span style={{ fontSize: 13 }}>À partir de</span>
                                  <span> {item.price} €</span>
                                </div>
                                <div className="button">
                                  <a
                                    href={'/fours-a-pizza-morello-forni/' + encodeURIComponent(item.title.trim())}
                                    title="En Savoir Plus"
                                  >
                                    En Savoir Plus
                                    <span>
                                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none"><path fill="#2d4fb3" d="M2.5 7.5a.5.5 0 1 0 0 1h9.7l-4.03 3.63a.5.5 0 0 0 .66.74l5-4.5a.5.5 0 0 0 0-.74l-5-4.5a.5.5 0 0 0-.66.74L12.2 7.5H2.5Z"/></svg>
                                    </span>
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        {/* <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <div className="viewArticles">
            <div style={{ marginBottom: 20 }}>
              <HashLink to={`/fours-a-pizza-morello-forni`}>{`Voir tous les fours à pizza ${p?.category}`}</HashLink>
            </div>
          </div>
        </div> */}
      </div>
    </div>
  );
};

export const pizzarella = [
  {
    id: 1,
    category: "presse",
    title: "Pizzarella version dessus du table",
    shortDescription: "La seule façonneuse capable de préparer en quelques secondes une base pizza comme les mains d'un expert pizzaiolo",
    price: "4.500,00",
    longDescription: "",
    image: "pizzarella.png",
  },
  {
    id: 2,
    category: "presse",
    title: "Pizzarella intégrée dans le banc à pizza",
    shortDescription: "Cette intégration révolutionnaire dans un moderne bancà pizza, conçu et réalisé exprès par Morello Forni, simplifie, optimise et rends plus rapide la préparation des produits, en exploitant à fond ses capacités. Pizzarella, dans son table à pizza, est incomparablement insérée au ras de la surface de travail, se harmonise, et expresse au fond sa productivité, au but de créer une synergie parfaite dans le flux de travail d'une traditionnelle pizzeria.",
    price: "4.500,00",
    longDescription: "",
    image: "pizzarella2.png",
  },
  {
    id: 2,
    category: "presse",
    title: "Pizzarella integrée dans commode en acier inox",
    shortDescription: "Pour offrir aux clients, déjà en possession d'un table à pizza, les avantages et l'ergonomie de Pizzarella affleurant le plan de travail, Morello Forni propose une commode en acier inoxydable, équipée de 6 tiroirs neutres pour les bacs à pizza et douée de roulettes, au but de pouvoir le poser à côté du banc à pizza.",
    price: "4.500,00",
    longDescription: "",
    image: "pizzarella3.png",
  }
];
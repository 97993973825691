import React from "react";
import {NavLink} from 'react-router-dom';
import {pizzarella} from "../articles/pizzarella";

export const Pizzarella = () => {
  return (
    <div className='container articlesContainer'>
      <div className="row">
        <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <div className="row">
            {pizzarella.map((item, i) => {
              return (
                <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 article" key={i}>
                  <div className="row">
                    <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                      <div className="contBoxFour">
                        <div className="row">
                          <div className="col-sm-12 col-md-12 col-lg-6 col-xl-4">
                            <div className="image">
                              <img
                                src={require('./../images/forni/' + item.image)}
                                alt={item.title}
                                onClick={() => window.open('/fours-a-pizza-morello-forni/' + encodeURIComponent(item.title.trim()), "_self")}
                              />
                            </div>
                          </div>
                          <div className="col-sm-12 col-md-12 col-lg-6 col-xl-8">
                            <div className="box">
                              <div className="headerBox">
                                <div className="title">
                                  <span>{item.title === "LP" ? "L/LP" : item.title}</span>
                                </div>
                                <div className="description">
                                  <span>{item.shortDescription}</span>
                                </div>
                              </div>
                              <div className="footerBox">
                                {/* <div className="price">
                                  <span style={{ fontSize: 13 }}>À partir de</span>
                                  <span> {item.price} €</span>
                                </div> */}
                                <div className="button">
                                  <a
                                    href={'/la-pizzarella'}
                                    title="En Savoir Plus"
                                  >
                                    En Savoir Plus
                                    <span>
                                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none"><path fill="#2d4fb3" d="M2.5 7.5a.5.5 0 1 0 0 1h9.7l-4.03 3.63a.5.5 0 0 0 .66.74l5-4.5a.5.5 0 0 0 0-.74l-5-4.5a.5.5 0 0 0-.66.74L12.2 7.5H2.5Z"/></svg>
                                    </span>
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        {/* <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <div className="viewArticles">
            <div style={{ marginBottom: 20 }}>
              <HashLink to={`/fours-a-pizza-morello-forni`}>{`Voir tous les fours à pizza ${p?.category}`}</HashLink>
            </div>
          </div>
        </div> */}
      </div>
    </div>
  );
  return (
    <div className='container'>
      <div className="row" style={{marginTop: 30}}>
        <div className="col-sm-12 col-md-6 col-lg-4 col-xl-4 image">
          <img src={require('./../images/forni/' + pizzarella[0].image)} alt="pizzarella" />
        </div>
        <div className="col-sm-12 col-md-6 col-lg-8 col-xl-8">
          <div className="title">
            <span>Pizzarella</span>
          </div>
          <div style={{marginBottom: 10}}>
            <span style={{fontWeight: 500}}>Une aide indispensable à la pizzeria</span>
          </div>
          <div>
            <span>La première et seule façonneuse semi-automatique, capable de préparer en quelques secondes une base pizza comme les mains d'un expert pizzaiolo.</span>
          </div>
          <br></br>
          <div>
            <span>Pizzarella est une machine révolutionnaire, qui ne maltraite ni stresse la pâte, avec son aide infatigable, étaler la pizza est un jeu d'enfant, accessible à tous.</span>
          </div>
          <div>
            <span>Sa particularité est d'élargir la pâte, en rendant simple et immédiate ce technique, qui normalement exige des années d'expérience et apprentissage.</span>
          </div>
          <br></br>
          <div>
            <span>Pizzarella travaille à température ambiante, ne lamine pas le patôn, ne le stresse, et ne transforme pas la pâte avec la chaleur, garde intactes les propriétés physiques et organoleptiques de votre pâton, juste comme le savoir-faire d'un expert pizzaiolo.</span>
          </div>
          <div className="viewArticles">
            <NavLink to={`/la-pizzarella`}>Voir d'autres modèles</NavLink>
          </div>
        </div>
      </div>
    </div>
  );
};

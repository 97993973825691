import React from "react";

export const Hero = () => {
  return (
    <div className="bannerContact">
      <div className="container">
        <h1>
          Revendeurs bienvenu, <br></br> service d'accompagnement et assistance technique.
        </h1>
        <p>Importateur pour la France des fours à pizza Morello Forni, pizzarella, diviseuses bouleuses et pieces de rechange.</p>
      </div>
    </div>
  );
};

export const HeroBanner = () => {
  return (
    <div className='container intestazione'>
      <h1 style={{display: "none"}}>Importateur pour la france des fours à pizzas morello forni</h1>
      <img src={require('./../images/slide.jpg')} alt="Hero fours" />
    </div>
  );
};

export const HeroPizzarella = () => {
  return (
    <div className='container'>
      {/* <h3>Importateur  pour la france de Pizzarella</h3> */}
      <img src={require('./../images/slide2.jpg')} alt="Hero pizzarella" />
    </div>
  );
};

export const Message = () => {
  return (
    <div className='container intestazione'>
      <h2>Des fours professionnels pour pizzeria</h2>
      <p>Fait en Italie, apprécié par les pizzaiolos, une gamme de four Morello Forni pour tous : à bois, au gaz, électrique ou hybride</p>
    </div>
  );
};

export const MessagePizzarella = () => {
  return (
    <div className='container intestazione'>
      <h2>Importateur pour la France de Pizzarella Morello Forni</h2>
      <p>La seule façonneuse capable de préparer en quelques secondes une base pizza comme les mains d'un expert pizzaiolo</p>
    </div>
  );
};
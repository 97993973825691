import React from "react";

export const Notice = () => {
  return (
    <div className="boxMessage privacy_policy_box">

      <h4>Finalites et modalites du traitement des donnees a caractere personnel</h4>

      <p>Les données à caractère personnel que vous avez fournies (« Données ») feront l'objet d'un traitement pour les finalités suivantes :</p>
      <ol type="a">
        <li>fournir le service demandé (« Service ») selon l'accord conclu entre Sarl Demat (« Société ») et vous-même ;</li>
        <li>autoriser la Société à réaliser des enquêtes de satisfaction clients (« Satisfaction Client ») concernant la qualité des biens et services de la Société en fonction de ses intérêts légitimes ;</li>
        <li>avec votre consentement exprès, envoyer des communications commerciales et des publicités concernant les produits et les services de la Société ou bien effectuer des études de marché (« Marketing ») ; </li>
        <li>avec votre consentement exprès, réaliser une analyse de vos comportements, habitudes et tendances de consommation afin d'améliorer les produits et services fournis par la Société ainsi que pour satisfaire vos attentes (« Profilage ») ;</li>
        <li>avec votre consentement exprès, communiquer les Données aux filiales et sociétés affiliées de la Société Sarl Demat, lesquels les traiteront pour envoyer des communications commerciales et des publicités relatives à leurs propres produits et services ou bien effectuer des études de marché (« Marketing des Tiers »).</li>
      </ol>
      <p>
      Les Données seront traitées sous format papier, automatisé ou électronique, et notamment par courrier postal ou électronique, par téléphone (par exemple : automates d'appel, SMS, MMS), par télécopie et par tout autre moyen (par exemple : sites web, applications mobiles).
      </p>

      <h4>Consequences de la non fourniture de donnees</h4>

      <p>La communication des Données n'est jamais obligatoire. Cependant, la non-fourniture des Données marquées comme obligatoires empêchera la Société de rendre le Service. D'autre part, la non-fourniture des Données facultatives vous permettra d'accéder au Service de toute façon.
      </p>

      <h4>Destinataires des données</h4>

      <p>Les Données peuvent être traitées par des personnes physiques et / ou morales, agissant au nom de la Société et dans le cadre d'obligations contractuelles spécifiques, basées dans des États Membres de l'UE ou dans des pays hors de l'UE. <br></br>
        Les Données peuvent être communiquées à des tiers pour se conformer à des obligations légales, pour exécuter des ordres émanant d'Autorités Publiques ou pour faire valoir un droit de la Société devant les autorités judiciaires.

      </p>

      <h4>Transfert de donnees en dehors de l'eee</h4>

      <p>Dans le cadre de ses relations contractuelles, la Société peut transférer les Données dans des pays hors de l'Espace Economique Européen (EEE), y compris les stocker dans des bases de données gérées par des entités agissant pour le compte de la Société. La gestion des bases de données et le traitement des Données sont liés aux finalités du traitement et sont effectués conformément à la loi sur la protection des données en vigueur. <br></br>
        Si les Données sont transférées en dehors de l'EEE, la Société prendra toutes les mesures contractuelles appropriées pour garantir une protection adéquate des Données, y compris - entre autres - des accords basés sur les clauses contractuelles types adoptées par la Commission Européenne pour régir le transfert de données personnelles en dehors de l'EEE.

      </p>

      <h4>Responsable du traitement et equipe du delegue a la protection des donnees</h4>

      <p>Le Responsable du Traitement est Sarl Demat . dont le siège social est situé 1472 Route des Arcs - 83720 - Trans-en-Provence, France.
      </p>

      <h4>Conservation des données</h4>

      <p>Les Données traitées pour fournir le Service et la Satisfaction Client seront conservées par la Société pendant la période considérée comme strictement nécessaire au regard de telles finalités. Concernant les Données traitées pour la fourniture du Service, la Société peut continuer à stocker ces Données pour une période plus longue, ce qui peut être nécessaire pour protéger les intérêts de la Société dans le cadre d'une éventuelle responsabilité liée à la fourniture du Service.
      </p>

      <p>Les Données traitées à des fins de Marketing et de Profilage seront conservées par la Société à partir du moment où vous donnez votre consentement jusqu'au moment où vous le retirez. Une fois le consentement retiré, les Données ne seront plus utilisées à ces fins, bien qu'elles puissent être conservées par la Société, notamment afin de protéger les intérêts de la Société dans le cadre d'une éventuelle responsabilité relative à ce traitement, à moins que des précisions supplémentaires ne soient fournies par l'Autorité de Contrôle à cet égard.</p>

      <h4>Vos droits</h4>

      <p>
        Vous pouvez exercer les droits suivants: <br></br>
      </p><ol>
        <li>droit d'accès signifie le droit d'obtenir de la Société la confirmation que vos Données sont ou ne sont pas traitées et, lorsqu'elles le sont, l'accès auxdites Données; </li>
        <li>droit de rectification et droit à l'effacement signifie le droit d'obtenir la rectification de Données inexactes et / ou incomplètes, ainsi que l'effacement de Données lorsque la demande est légitime;</li>
        <li>droit à la limitation du traitement signifie le droit de demander la suspension du traitement lorsque la demande est légitime;</li>
        <li>droit à la portabilité des données signifie le droit d'obtenir des Données dans un format structuré, couramment utilisé et lisible, ainsi que le droit de transférer des Données à d'autres responsables de traitement;</li>
        <li>droit d'opposition signifie le droit de s'opposer au traitement des Données lorsque la demande est légitime, y compris lorsque les Données sont traitées pour le marketing ou le profilage, le cas échéant;</li>
        <li>droit d'introduire une réclamation auprès d'une autorité de contrôle en cas de traitement illégal des Données.</li>
      </ol>
      <p>
      Vous pouvez exercer les droits susmentionnés en écrivant à Sarl Demat, 1472 Route des Arcs - 83720 - Trans-en-Provence, France ou en envoyant un email à l'adresse <a href="mailto:contact.demat83@gmail.com" target="_top">contact.demat83@gmail.com</a>.
      </p>

    </div>
  );
};
import React, {useEffect} from 'react';
import {Hero, Message, MessagePizzarella} from './../components/Hero';
import {Fours} from './../components/Fours';
import {Pizzarella} from './../components/Pizzarella';
import {Loader} from '../helpers/Loader/Loader';
import { Helmet, HelmetData } from 'react-helmet-async';
import './../App.css';

export const Home = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const helmetData = new HelmetData({});
  
	return (
		<React.Fragment>
      <Helmet helmetData={helmetData} >
        <title>Fours à pizza professionnels - Fours Demat</title>
        <meta name="description" content="Fours Demat vous invite à consulter toute sa gamme de fours à pizzas et matériels pour pizzeria" />
        <meta property="og:title" content="Fours à pizza professionnels"/>
        <meta property="og:description" content="Fours Demat vous invite à consulter toute sa gamme de fours à pizzas et matériels pour pizzeria" />
        <meta property="og:type" content="article" />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:image" content={require('./../images/logo-small.png')} />
        <meta property="og:site_name" content="Fours Demat" />
        <meta property="og:section" content="Home" />
        <link rel="canonical" href="https://www.foursdemat.com/" />
      </Helmet>
      <Loader />
			<Hero />
      <Message />
      <Fours category="statiques" articles={8} random={true} />
      <MessagePizzarella />
      <Pizzarella />
		</React.Fragment>
	);
};
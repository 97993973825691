import React from "react";
import {Notice} from "./Privacy/Notice";
import {Marketing} from "./Privacy/Marketing";
import {Profilation} from "./Privacy/Profilation";
import {ThirdParty} from "./Privacy/ThirdParty";

export const CustomModal = (p) => {
  return (
    <div className="customModal">
      <div className="modalContainer">
        <div className="head">          
          {p.typeModal === "notice" && <h1>Note d'information</h1>}
          {p.typeModal === "marketing" && <h1>Communications marketing</h1>}
          {p.typeModal === "profilation" && <h1>Profilage</h1>}
          {p.typeModal === "thirdParty" && <h1>Communications à des tiers</h1>}
          <span
            role="button"
            onClick={p?.hideModal}
          >
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none"><path fill="#242424" d="M.397.554.47.47a.75.75 0 0 1 .976-.073L1.53.47 8 6.939l6.47-6.47a.75.75 0 1 1 1.06 1.061L9.061 8l6.47 6.47a.75.75 0 0 1 .072.976l-.073.084a.75.75 0 0 1-.976.073l-.084-.073L8 9.061l-6.47 6.47A.75.75 0 0 1 .47 14.47L6.939 8 .469 1.53A.75.75 0 0 1 .398.554L.47.47.397.554Z"/></svg>
          </span>
        </div>
        <div className="body">
          {p.typeModal === "notice" && 
            <Notice />
          }
          {p.typeModal === "marketing" && 
            <Marketing />
          }
          {p.typeModal === "profilation" && 
            <Profilation />
          }
          {p.typeModal === "thirdParty" &&
            <ThirdParty />
          }
        </div>
      </div>
    </div>
  );
};

export const Modal = (p) => {
  return (
    <div className="customModal">
      <div className="modalContainer">
        <div className="head">          
          <h1>Email envoyé</h1>
          <span
            role="button"
            onClick={p?.hideModal}
          >
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none"><path fill="#242424" d="M.397.554.47.47a.75.75 0 0 1 .976-.073L1.53.47 8 6.939l6.47-6.47a.75.75 0 1 1 1.06 1.061L9.061 8l6.47 6.47a.75.75 0 0 1 .072.976l-.073.084a.75.75 0 0 1-.976.073l-.084-.073L8 9.061l-6.47 6.47A.75.75 0 0 1 .47 14.47L6.939 8 .469 1.53A.75.75 0 0 1 .398.554L.47.47.397.554Z"/></svg>
          </span>
        </div>
        <div className="body">
          Merci de nous avoir contacté, nous vous répondrons dans les plus brefs délais!
        </div>
      </div>
    </div>
  );
};
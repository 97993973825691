import React, { useState } from "react";
import { CustomModal, Modal } from "./../components/CustomModal";
// import {Regex} from "./../helpers/Regex/Regex";

export const ContactFooter = () => {

  const [inputs, setInputs] = useState({});
  const [errors, setErrors] = useState({});
  const [alerts, setAlerts] = useState({});
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [openModal, setOpenModal] = useState(false);
  const [typeModal, setTypeModal] = useState("");
  const [openModalSendMessage, setOpenModalSendMessage] = useState(false);

  const showModal = (p) => {
    setTypeModal(p);
    setOpenModal(true);
  };
  const hideModal = () => {
    setOpenModal(false);
    setOpenModalSendMessage(false);
  };

  const showModalSendMessage = (p) => {
    setOpenModalSendMessage(true);
  };

  window.addEventListener("resize", () => {
    setWindowWidth(window.innerWidth);
  });

  const REGEX_FIELDS = new RegExp(/[0-9`!@#$%^&*()_+\-=\[\]{};':"\\|,<>\/?~]/);
  const REGEX_ADDRESS = new RegExp(/[`!@#$%^&*()_+\-=\[\]{};':"\\|<>\/?~]/);
  const REGEX_MESSAGE = new RegExp(/[`@#$^*+\-=\[\]{};\\|<>\/~]/);
  const REGEX_PHONE = new RegExp(/[a-zA-Z`!@#$%^&*()_+\-=\[\]{};':"\\|,<>\/?~]/);
  const REGEX_MAIL = new RegExp(/[`!#$%^*()_+\=\[\]{};':"\\|,<>\/?~]/);

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;

    if (name === "phone") {
      if (REGEX_PHONE.test(value)) {
        setAlerts(values => ({ ...values, [name]: "error" }));
      } else {
        setInputs(values => ({ ...values, [name]: value }));
        setErrors(values => ({ ...values, [name]: "" }));
        setAlerts(values => ({ ...values, [name]: "" }));
      }
    } else if (name === "email") {
      if (REGEX_MAIL.test(value)) {
        setAlerts(values => ({ ...values, [name]: "error" }));
      } else {
        setInputs(values => ({ ...values, [name]: value }));
        setErrors(values => ({ ...values, [name]: "" }));
        setAlerts(values => ({ ...values, [name]: "" }));
      }
    } else if (name === "address") {
      if (REGEX_ADDRESS.test(value)) {
        setAlerts(values => ({ ...values, [name]: "error" }));
      } else {
        setInputs(values => ({ ...values, [name]: value }));
        setErrors(values => ({ ...values, [name]: "" }));
        setAlerts(values => ({ ...values, [name]: "" }));
      }
    } else if (name === "message") {
      if (REGEX_MESSAGE.test(value)) {
        setAlerts(values => ({ ...values, [name]: "error" }));
      } else {
        setInputs(values => ({ ...values, [name]: value }));
        setErrors(values => ({ ...values, [name]: "" }));
        setAlerts(values => ({ ...values, [name]: "" }));
      }
    } else {
      if (REGEX_FIELDS.test(value)) {
        setAlerts(values => ({ ...values, [name]: "error" }));
      } else {
        setInputs(values => ({ ...values, [name]: value }));
        setErrors(values => ({ ...values, [name]: "" }));
        setAlerts(values => ({ ...values, [name]: "" }));
      }
    }

  };

  const validate = () => {

    const form = document.querySelector('.formContact form');

    if (inputs.name === undefined || inputs.name === "") {
      setErrors(values => ({ ...values, name: "error" }));
      window.scrollTo(0, form.offsetTop - 100);
      return false;
    } else {
      setErrors(values => ({ ...values, name: "" }));
    }

    if (inputs.surname === undefined || inputs.surname === "") {
      setErrors(values => ({ ...values, surname: "error" }));
      window.scrollTo(0, form.offsetTop - 100);
      return false;
    } else {
      setErrors(values => ({ ...values, surname: "" }));
    }

    if (inputs.email === undefined || inputs.email === "") {
      setErrors(values => ({ ...values, email: "error" }));
      window.scrollTo(0, form.offsetTop - 100);
      return false;
    } else {
      setErrors(values => ({ ...values, email: "" }));
    }

    if (inputs.phone === undefined || inputs.phone === "") {
      setErrors(values => ({ ...values, phone: "error" }));
      window.scrollTo(0, form.offsetTop - 100);
      return false;
    } else {
      setErrors(values => ({ ...values, phone: "" }));
    }

    // if (inputs.address === undefined || inputs.address === "") {
    //   setErrors(values => ({...values, address: "error"}));
    //   window.scrollTo(0, form.offsetTop - 100);
    // }else{
    //   setErrors(values => ({...values, address: ""}));
    // }

    // if (inputs.message === undefined || inputs.message === "") {
    //   setErrors(values => ({...values, message: "error"}));
    //   window.scrollTo(0, form.offsetTop - 100);
    // }else{
    //   setErrors(values => ({...values, message: ""}));
    // }

    if (inputs.isPrivacyMarketing === undefined || inputs.isPrivacyMarketing === "") {
      setErrors(values => ({ ...values, isPrivacyMarketing: "error" }));
      window.scrollTo(0, form.offsetTop - 100);
      return false;
    } else {
      setErrors(values => ({ ...values, isPrivacyMarketing: "" }));
    }

    if (inputs.isPrivacyProfilation === undefined || inputs.isPrivacyProfilation === "") {
      setErrors(values => ({ ...values, isPrivacyProfilation: "error" }));
      window.scrollTo(0, form.offsetTop - 100);
      return false;
    } else {
      setErrors(values => ({ ...values, isPrivacyProfilation: "" }));
    }

    if (inputs.isPrivacyThirdPart === undefined || inputs.isPrivacyThirdPart === "") {
      setErrors(values => ({ ...values, isPrivacyThirdPart: "error" }));
      window.scrollTo(0, form.offsetTop - 100);
      return false;
    } else {
      setErrors(values => ({ ...values, isPrivacyThirdPart: "" }));
    }

    return true;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const isValid = validate();
    if (isValid) {
      setInputs(values => ({ ...values, ["model"]: document.getElementById('model').value }));
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(inputs)
      };
      const response = await fetch('https://www.foursdemat.com/send-email.php', requestOptions);
      const data = await response.json();
      // console.log(data);
      // console.log(inputs);
      if (data?.send === 1) {
        document.querySelector('.formContact form').reset();
        setInputs({});
        showModalSendMessage(true);
      }

    }
  }


  return (
    <React.Fragment>
      <div className="bannerContact">
        <div className="container">
          <h1>Avez-vous besoin de plus d'informations?</h1>
          <p>Contactez-nous en remplissant le formulaire, nous vous répondrons dans les plus brefs délais.</p>
        </div>
      </div>
      <div className='container contactFooter' id="contactFooter">
        <div className="row">
          <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
            <div className="contContact">
              <div className="row">
                <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6 contactInfo">
                  <div className="company">
                    <span>Sarl DEMAT</span>
                  </div>
                  <div className="address">
                    <span>
                      1472 Route des Arcs - 83720
                      <br></br>
                      Trans-en-Provence
                    </span>
                  </div>
                  <div className="phone">
                    <span>
                      <span>Tél: </span>
                      {windowWidth < 576
                        ?
                        <a href="tel:+330987703129">(+33) 09.87.70.31.29</a>
                        :
                        <span>(+33) 09.87.70.31.29</span>
                      }
                    </span>
                  </div>
                  <div className="phoneMobile">
                    <span>
                      <span>Portable: </span>
                      {windowWidth < 576
                        ?
                        <a href="tel:+33611351036">(+33) 06.11.35.10.36</a>
                        :
                        <span>(+33) 06.11.35.10.36</span>
                      }
                    </span>
                  </div>
                  <div className="email">
                    <span>Email: <a href="mailto:contact.demat83@gmail.com">contact.demat83@gmail.com</a></span>
                  </div>
                  <div className="hours">
                    <span>Horaires : 8h30 à 12h00 - 14h30 à 18h00</span>
                  </div>
                </div>
                <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6 formContact">
                  <form onSubmit={handleSubmit}>
                    {/* <div className="row">
                <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 mb-15">
                  <span style={{ fontWeight: 500 }}>Veuillez svp remplir les champs pour demander plus d'infos ou réserver un rendez-vous</span>
                </div>
              </div> */}
                    <div className="row">
                      <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 mb-15">
                        <span style={{ fontSize: 12, fontWeight: 500 }}>Les champs marqués d'un * sont obligatoires</span>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6 mb-15">
                        <input
                          onChange={handleChange}
                          className={`textField ${(errors.name === "error" || alerts.name === "error") ? "error" : ""}`}
                          type="text"
                          name="name"
                          placeholder="Prénom*"
                        ></input>
                        {errors.name === "error" && <span>Champ obligatoire</span>}
                        {alerts.name === "error" && <span>Caractères interdits</span>}
                      </div>
                      <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6 mb-15">
                        <input
                          onChange={handleChange}
                          className={`textField ${(errors.surname === "error" || alerts.surname === "error") ? "error" : ""}`}
                          type="text"
                          name="surname"
                          placeholder="Nom*"
                        ></input>
                        {errors.surname === "error" && <span>Champ obligatoire</span>}
                        {alerts.surname === "error" && <span>Caractères interdits</span>}
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6 mb-15">
                        <input
                          onChange={handleChange}
                          className={`textField ${(errors.email === "error" || alerts.email === "error") ? "error" : ""}`}
                          type="email"
                          name="email"
                          placeholder="Votre Email*"
                        ></input>
                        {errors.email === "error" && <span>Champ obligatoire</span>}
                        {alerts.email === "error" && <span>Caractères interdits</span>}
                      </div>
                      <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6 mb-15">
                        <input
                          onChange={handleChange}
                          className={`textField ${(errors.phone === "error" || alerts.phone === "error") ? "error" : ""}`}
                          type="text"
                          name="phone"
                          placeholder="Téléphone*"
                        ></input>
                        {errors.phone === "error" && <span>Champ obligatoire</span>}
                        {alerts.phone === "error" && <span>Caractères interdits</span>}
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 mb-15">
                        <input
                          onChange={handleChange}
                          className={`textField ${(errors.address === "error" || alerts.address === "error") ? "error" : ""}`}
                          type="text"
                          name="address"
                          placeholder="Votre Adresse"
                        ></input>
                        {errors.address === "error" && <span>Champ obligatoire</span>}
                        {alerts.address === "error" && <span>Caractères interdits</span>}
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 mb-15">
                        <textarea
                          onChange={handleChange}
                          className={`textareaField ${(errors.message === "error" || alerts.message === "error") ? "error" : ""}`}
                          name="message"
                          placeholder="Message"
                        ></textarea>
                        {errors.message === "error" && <span>Champ obligatoire</span>}
                        {alerts.message === "error" && <span>Caractères interdits</span>}
                        <input id="model" className="textField" type="text" name="model" placeholder="Modele"></input>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 mb-15">
                        <div className="checkPrivacy">
                          <span>CONSENTEMENT</span>
                        </div>
                        <div className="checkPrivacy">
                          <span>Après avoir lu la <span className="linkPrivacy" onClick={() => showModal("notice")}>note d'information</span></span>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 mb-15">
                        <div className={`marketing checkPrivacy ${errors.isPrivacyMarketing === "error" ? "error" : ""}`}>
                          <div className="mui-radio">
                            <input
                              id="marketing-yes-td"
                              onChange={handleChange}
                              className="MARKETING_MAIL1"
                              type="radio"
                              name="isPrivacyMarketing"
                              value="Y"
                            ></input>
                            <label htmlFor="marketing-yes-td">J'accepte</label>
                          </div>
                          <div className="mui-radio">
                            <input
                              id="marketing-no-td"
                              onChange={handleChange}
                              className="MARKETING_MAIL1"
                              type="radio"
                              name="isPrivacyMarketing"
                              value="N"
                            ></input>
                            <label htmlFor="marketing-no-td">Je refuse</label>
                          </div>
                        </div>
                        <div className="marketing checkPrivacy">
                          <span className="linkPrivacy" onClick={() => showModal("marketing")}>De recevoir des communications marketing</span>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 mb-15">
                        <div className={`profilation checkPrivacy ${errors.isPrivacyProfilation === "error" ? "error" : ""}`}>
                          <div className="mui-radio">
                            <input
                              id="profilazione-yes-td"
                              onChange={handleChange}
                              className="PRIVACYPROFILATION"
                              type="radio"
                              name="isPrivacyProfilation"
                              value="Y"
                            ></input>
                            <label htmlFor="profilazione-yes-td">J'accepte</label>
                          </div>
                          <div className="mui-radio">
                            <input
                              id="profilazione-no-td"
                              onChange={handleChange}
                              className="PRIVACYPROFILATION"
                              type="radio"
                              name="isPrivacyProfilation"
                              value="N"
                            ></input>
                            <label htmlFor="profilazione-no-td">Je refuse</label>
                          </div>
                        </div>
                        <div className="profilation checkPrivacy">
                          <span className="linkPrivacy" onClick={() => showModal("profilation")}>Que mes données soient utilisées à des fins de profilage</span>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 mb-15">
                        <div className={`thirdyParty checkPrivacy ${errors.isPrivacyThirdPart === "error" ? "error" : ""}`}>
                          <div className="mui-radio">
                            <input
                              id="terzi-yes-td"
                              onChange={handleChange}
                              className="PRIVACYTHIRDPARTY"
                              type="radio"
                              name="isPrivacyThirdPart"
                              value="Y"
                            ></input>
                            <label htmlFor="terzi-yes-td">J'accepte</label>
                          </div>
                          <div className="mui-radio">
                            <input
                              id="terzi-no-td"
                              onChange={handleChange}
                              className="PRIVACYTHIRDPARTY"
                              type="radio"
                              name="isPrivacyThirdPart"
                              value="N"
                            ></input>
                            <label htmlFor="terzi-no-td">Je refuse</label>
                          </div>
                        </div>
                        <div className="thirdyParty checkPrivacy">
                          <span className="linkPrivacy" onClick={() => showModal("thirdParty")}>Que les données soient communiquées à des tiers pour leurs opérations de marketing</span>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 mb-15">
                        <button
                          type="submit"
                          tabIndex={0}
                          onClick={() => { }}
                          className="send"
                        >Envoyer</button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {openModal &&
        <CustomModal
          hideModal={hideModal}
          typeModal={typeModal}
        />
      }

      {openModalSendMessage &&
        <Modal
          hideModal={hideModal}
        />
      }

    </React.Fragment>
  );
};

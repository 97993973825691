import React from "react";

export const Profilation = () => {
  return (
    <div className="contPrivacyMarketing">
      <p>
      que mes données à caractère personnel fassent l'objet d'un traitement par <span style={{fontWeight: 500}}>Sarl Demat</span> pour analyser mes préférences et recevoir des communications commerciales personnalisées, comme indiqué au point "d" de la note d'information.
      </p>
    </div>
  );
};
import React, { useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { Loader } from '../helpers/Loader/Loader';
import { Separator } from './../components/Separator';
import { Helmet, HelmetData } from 'react-helmet-async';
import './../App.css';

export const Pizzarella = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const contact = () => {
    const element = document.getElementById('contactFooter');
    if (element) {
      // element.scrollIntoView({ behavior: 'smooth' });
      window.scrollTo(0, element.offsetTop - 92);
    }
  };

  const helmetData = new HelmetData({});

  return (
    <React.Fragment>
      <Helmet helmetData={helmetData} >
        <title>Pizzarella, façonneuse à pizza Morello Forni - Fours Demat</title>
        <meta name="description" content="Pizzarella, la première et seule façonneuse semi-automatique, capable de préparer en quelques secondes une base pizza comme les mains d'un expert pizzaiolo." />
        <meta property="og:title" content="Pizzarella, façonneuse à pizza Morello Forni" />
        <meta property="og:description" content="Pizzarella, la première et seule façonneuse semi-automatique, capable de préparer en quelques secondes une base pizza comme les mains d'un expert pizzaiolo." />
        <meta property="og:type" content="article" />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:image" content={require('./../images/forni/pizzarella.png')} />
        <meta property="og:site_name" content="Fours Demat" />
        <meta property="og:section" content="Pizzarella" />
        <link rel="canonical" href="https://www.foursdemat.com/la-pizzarella" />
      </Helmet>
      <Loader />
      {/* <Separator /> */}
      <div className='pizzarellaMorello'>
        <div className='bannerContact' style={{ marginBottom: 30 }}>
          <div className='container'>
            <div className='row'>
              <h1>La Pizzarella Morello Forni</h1>
              <p className='morelloDescription'>
                Pizzarella est une machine révolutionnaire, qui ne maltraite ni stresse la pâte, avec son aide infatigable, étaler la pizza est un jeu d'enfant, accessible à tous.
                {/* <br />
                Sa particularité est d'élargir la pâte, en rendant simple et immédiate ce technique, qui normalement exige des années d'expérience et apprentissage.
                <br />
                Pizzarella travaille à température ambiante, ne lamine pas le patôn, ne le stresse, et ne transforme pas la pâte avec la chaleur, garde intactes les propriétés physiques et organoleptiques de votre pâton, juste comme le savoir-faire d'un expert pizzaiolo. */}
              </p>
            </div>
          </div>
        </div>
        <div className='container'>
          <div className='row'>
            <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 article">
              <div className='contBoxPizzarella'>
                <div className='row'>
                  <div className="col-sm-12 col-md-4 col-lg-4 col-xl-2">
                    <div className='image'>
                      <img src={require('./../images/forni/pizzarella.png')} alt="Pizzarella Morello Forni" />
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-8 col-lg-8 col-xl-10 description">
                    <div className='title'>
                      <span>Pizzarella version "dessus du table"</span>
                    </div>
                    <div>
                      <span>Version à placer sur soutien capable, à côté ou sur un banc à pizza déjà acheté, ou sur son support exprés dédié et conçu pour ça; Pizzarella version « dessus du table » est facile à positionner, et peu encombrant en taille ou aspect, si comparée aux façonneuses d'ancienne génération.</span>
                    </div>
                    <div className='documentation'>
                      <div>
                        <a
                          target={"blank"}
                          href={require('../pdf/morello_pizzarella_fr.pdf')}
                          title="Télécharger informations techniques Pizzarella"
                        >
                          <span>Télécharger informations techniques</span>
                          <span>
                            <svg xmlns="http://www.w3.org/2000/svg" width="10" height="13" fill="none"><path fill="#2d4fb3" d="M.5 12h9a.5.5 0 0 1 .09.992L9.5 13h-9a.5.5 0 0 1-.09-.992L.5 12h9-9ZM4.91.008 5 0a.5.5 0 0 1 .492.41L5.5.5v8.792l2.682-2.681a.5.5 0 0 1 .638-.058l.07.058a.5.5 0 0 1 .057.638l-.058.069-3.535 3.536a.5.5 0 0 1-.638.057l-.07-.057-3.535-3.536a.5.5 0 0 1 .638-.765l.069.058L4.5 9.292V.5a.5.5 0 0 1 .41-.492L5 0l-.09.008Z"></path></svg>
                          </span>
                        </a>
                      </div>
                      <div>
                        <a
                          target={"blank"}
                          href={`https://www.youtube.com/embed/H-RiFGhQaXM?html5=1&amp;enablejsapi=1&amp;autoplay=0&amp;rel=0&amp;showinfo=0&amp;modestbranding=1&amp;controls=1&amp;autohide=0&amp;vq=large`}
                          title="Regarder la vidéo Pizzarella"
                          className='videoButton'
                        >
                          <span>Regarder la vidéo</span>
                          <span>
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none"><g clip-path="url(#a)"><path fill="#2d4fb3" d="M19.6 5.15a2.51 2.51 0 0 0-1.77-1.78c-1.56-.42-7.81-.42-7.81-.42s-6.25 0-7.81.42A2.51 2.51 0 0 0 .44 5.15C.02 6.72.02 10 .02 10s0 3.27.42 4.85a2.51 2.51 0 0 0 1.77 1.77c1.56.43 7.81.43 7.81.43s6.25 0 7.81-.43a2.51 2.51 0 0 0 1.77-1.77c.42-1.58.42-4.85.42-4.85s0-3.28-.42-4.85Z"></path><path fill="#FEFEFE" d="M7.97 12.97V7.03L13.2 10l-5.23 2.97Z"></path></g><defs><clipPath id="a"><path fill="#fff" d="M0 0h20v20H0z"></path></clipPath></defs></svg>
                          </span>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 article">
              <div className='contBoxPizzarella'>
                <div className='row'>
                  <div className="col-sm-12 col-md-4 col-lg-4 col-xl-2">
                    <div className='image'>
                      <img src={require('./../images/forni/pizzarella2.png')} alt="Pizzarella Morello Forni" />
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-8 col-lg-8 col-xl-10 description">
                    <div className='title'>
                      <span>Pizzarella intégrée dans le banc à pizza</span>
                    </div>
                    <div>
                      <span>Cette intégration révolutionnaire dans un moderne bancà pizza, conçu et réalisé exprès par Morello Forni, simplifie, optimise et rends plus rapide la préparation des produits, en exploitant à fond ses capacités. Pizzarella, dans son table à pizza, est incomparablement insérée au ras de la surface de travail, se harmonise, et expresse au fond sa productivité, au but de créer une synergie parfaite dans le flux de travail d'une traditionnelle pizzeria.</span>
                    </div>
                    <div className='documentation'>
                      <div>
                        <a
                          target={"blank"}
                          href={require('../pdf/morello_pizzarella_fr.pdf')}
                          title="Télécharger informations techniques Pizzarella"
                        >
                          <span>Télécharger informations techniques</span>
                          <span>
                            <svg xmlns="http://www.w3.org/2000/svg" width="10" height="13" fill="none"><path fill="#2d4fb3" d="M.5 12h9a.5.5 0 0 1 .09.992L9.5 13h-9a.5.5 0 0 1-.09-.992L.5 12h9-9ZM4.91.008 5 0a.5.5 0 0 1 .492.41L5.5.5v8.792l2.682-2.681a.5.5 0 0 1 .638-.058l.07.058a.5.5 0 0 1 .057.638l-.058.069-3.535 3.536a.5.5 0 0 1-.638.057l-.07-.057-3.535-3.536a.5.5 0 0 1 .638-.765l.069.058L4.5 9.292V.5a.5.5 0 0 1 .41-.492L5 0l-.09.008Z"></path></svg>
                          </span>
                        </a>
                      </div>
                      <div>
                        <a
                          target={"blank"}
                          href={`https://www.youtube.com/embed/H-RiFGhQaXM?html5=1&amp;enablejsapi=1&amp;autoplay=0&amp;rel=0&amp;showinfo=0&amp;modestbranding=1&amp;controls=1&amp;autohide=0&amp;vq=large`}
                          title="Regarder la vidéo Pizzarella"
                          className='videoButton'
                        >
                          <span>Regarder la vidéo</span>
                          <span>
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none"><g clip-path="url(#a)"><path fill="#2d4fb3" d="M19.6 5.15a2.51 2.51 0 0 0-1.77-1.78c-1.56-.42-7.81-.42-7.81-.42s-6.25 0-7.81.42A2.51 2.51 0 0 0 .44 5.15C.02 6.72.02 10 .02 10s0 3.27.42 4.85a2.51 2.51 0 0 0 1.77 1.77c1.56.43 7.81.43 7.81.43s6.25 0 7.81-.43a2.51 2.51 0 0 0 1.77-1.77c.42-1.58.42-4.85.42-4.85s0-3.28-.42-4.85Z"></path><path fill="#FEFEFE" d="M7.97 12.97V7.03L13.2 10l-5.23 2.97Z"></path></g><defs><clipPath id="a"><path fill="#fff" d="M0 0h20v20H0z"></path></clipPath></defs></svg>
                          </span>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 article">
              <div className='contBoxPizzarella'>
                <div className='row'>
                  <div className="col-sm-12 col-md-4 col-lg-4 col-xl-2">
                    <div className='image'>
                      <img src={require('./../images/forni/pizzarella3.png')} alt="Pizzarella Morello Forni" />
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-8 col-lg-8 col-xl-10 description">
                    <div className='title'>
                      <span>Pizzarella integrée dans commode en acier inox</span>
                    </div>
                    <div>
                      <span>Pour offrir aux clients, déjà en possession d'un table à pizza, les avantages et l'ergonomie de Pizzarella affleurant le plan de travail, Morello Forni propose une commode en acier inoxydable, équipée de 6 tiroirs neutres pour les bacs à pizza et douée de roulettes, au but de pouvoir le poser à côté du banc à pizza.</span>
                    </div>
                    <div className='documentation'>
                      <div>
                        <a
                          target={"blank"}
                          href={require('../pdf/morello_pizzarella_fr.pdf')}
                          title="Télécharger informations techniques Pizzarella"
                        >
                          <span>Télécharger informations techniques</span>
                          <span>
                            <svg xmlns="http://www.w3.org/2000/svg" width="10" height="13" fill="none"><path fill="#2d4fb3" d="M.5 12h9a.5.5 0 0 1 .09.992L9.5 13h-9a.5.5 0 0 1-.09-.992L.5 12h9-9ZM4.91.008 5 0a.5.5 0 0 1 .492.41L5.5.5v8.792l2.682-2.681a.5.5 0 0 1 .638-.058l.07.058a.5.5 0 0 1 .057.638l-.058.069-3.535 3.536a.5.5 0 0 1-.638.057l-.07-.057-3.535-3.536a.5.5 0 0 1 .638-.765l.069.058L4.5 9.292V.5a.5.5 0 0 1 .41-.492L5 0l-.09.008Z"></path></svg>
                          </span>
                        </a>
                      </div>
                      <div>
                        <a
                          target={"blank"}
                          href={`https://www.youtube.com/embed/H-RiFGhQaXM?html5=1&amp;enablejsapi=1&amp;autoplay=0&amp;rel=0&amp;showinfo=0&amp;modestbranding=1&amp;controls=1&amp;autohide=0&amp;vq=large`}
                          title="Regarder la vidéo Pizzarella"
                          className='videoButton'
                        >
                          <span>Regarder la vidéo</span>
                          <span>
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none"><g clip-path="url(#a)"><path fill="#2d4fb3" d="M19.6 5.15a2.51 2.51 0 0 0-1.77-1.78c-1.56-.42-7.81-.42-7.81-.42s-6.25 0-7.81.42A2.51 2.51 0 0 0 .44 5.15C.02 6.72.02 10 .02 10s0 3.27.42 4.85a2.51 2.51 0 0 0 1.77 1.77c1.56.43 7.81.43 7.81.43s6.25 0 7.81-.43a2.51 2.51 0 0 0 1.77-1.77c.42-1.58.42-4.85.42-4.85s0-3.28-.42-4.85Z"></path><path fill="#FEFEFE" d="M7.97 12.97V7.03L13.2 10l-5.23 2.97Z"></path></g><defs><clipPath id="a"><path fill="#fff" d="M0 0h20v20H0z"></path></clipPath></defs></svg>
                          </span>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='container'>
          <div className='contactezNous'>
            <a
              href={'/contact'}
              title="Contactez-nous et nous serons heureux de vous fournir plus d'informations!"
            >
              Contactez-nous et nous serons heureux de vous fournir plus d'informations!
            </a>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
import React, { useEffect } from 'react';
// import {Hero} from './../components/Hero';
import { Fours } from '../components/Fours';
import {Loader} from '../helpers/Loader/Loader';
import {Separator} from './../components/Separator';
import { Helmet, HelmetData } from 'react-helmet-async';
import './../App.css';

export const FourMorello = (p) => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const helmetData = new HelmetData({});

  return (
    <React.Fragment>
      <Helmet helmetData={helmetData} >
        <title>Fours à pizza Morello Forni - Fours Demat</title>
        <meta name="description" content="Fours Demat vous invitee à consulter toute sa gamme de fours à pizza Morello Forni" />
        <meta property="og:title" content="Fours à pizza Morello Forni"/>
        <meta property="og:description" content="Fours Demat vous invitee à consulter toute sa gamme de fours à pizza Morello Forni" />
        <meta property="og:type" content="article" />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:image" content={require('./../images/logo-small.png')} />
        <meta property="og:site_name" content="Fours Demat" />
        <meta property="og:section" content="Fours à pizza" />
        <link rel="canonical" href="https://www.foursdemat.com/fours-a-pizza-morello-forni" />
      </Helmet>
      <Loader />
      {/* <Separator /> */}
      <div className='foursMorello'>
        <div className='bannerContact' style={{marginBottom: 30}}>
          <div className='container'>
            <div className='row'>
              <h1>Fours à pizza Morello Forni</h1>
              <p className='morelloDescription'>Constructeurs depuis 1969 de fours à pizza professionnels. La conception, le développement et la recherche ont été depuis toujours les valeurs les plus importantes dans l'évolution de cette entreprise familiale.</p>
            </div>
          </div>
        </div>
        <Fours category="statiques" viewTag={true} />
        <Fours category="rotatif" viewTag={true} />
      </div>
    </React.Fragment>
  );
};
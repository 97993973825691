export const fours = [
  {
    id: 1,
    pdf: "L-LP.pdf",
    category: "statiques",
    title: "LP",
    shortDescription: "Fours à bois fabriqués avec des matériaux réfractaires vibrés à haute teneur d'alumine garantie 10 ans.",
    four: "bois",
    sole: "bois",
    price: "5.031,00",
    longDescription: "Fours à bois fabriqués avec un matériaux réfractaire vibré à haute teneur d'alumine garantie 10 ans. Doté d'un tiroir pour la récolte des cendres et d'une grille de foyer qui ameliore la combustion et l'hygiène du four. Les fours de la série \"LP\" sont prédisposés pour être facilement transformable au gaz comme la série Morello \"PG\". Le devant en briques rustiques apparentes peut être livré avec façade de forme arrondie ou plate suivant la demande. Fours dotés d'une grande masse réfractaire qui produit un effet important de volant thermique et assure la stabilité de température pour permettre une cuisson rapide et uniforme. L'isolation est assurée par la mise en place d'une fibre minérale à haut pouvoir isolant.",
    image: "lp.png",
    type: [
      {
        standard: {image: "lp.png"},
        cupole: {image: "lp_cup.png"},
        mosaique: {image: "lp_mos.png"}
      }
    ],
    models: [
      {name: "L/LP75", capacity: "2", production: "30"},
      {name: "L/LP100", capacity: "4", production: "60"},
      {name: "L/LP110", capacity: "5", production: "75"},
      {name: "L/LP130", capacity: "8", production: "110"},
      {name: "L/LP150", capacity: "10", production: "140"},
      {name: "L/LP180", capacity: "15", production: "180"},
    ]
  },
  {
    id: 2,
    pdf: "MIX.pdf",
    category: "statiques",
    title: "MIX",
    shortDescription: "Four à bois dotée de tiroir pour la récupération des cendres et d'une grille de foyer.",
    four: "bois",
    sole: "gaz",
    price: "13.840,00",
    longDescription: "La série \"MIX\" est un four à bois dotée de tiroir pour la récupération des cendres et d'une grille de foyer. Un brûleur à gaz avec contrôle digitale assure le maintien en temperature de la sole. L'effet combiné des deux systèmes bois et gaz permet d'obtenir des prestations de cuisson parfaite avec des grandes charges de travail en autorisant des cuissons rapides et uniformes grâce à la stabilité thermique du four. Les fours \"MIX\" sont équipés avec nos brûleurs atmosphériques exclusifs qui sont exempts d'entretien, peuvent fonctionner au gaz naturel ou GPL. L'action des brûleurs de sole permet une économie du 50% de bois en comparaison à un four traditionnel. Les fours de la série \"MIX\" sont prévus pour être facilement convertis au gaz comme la série Morello \"FG\".",
    image: "mix_base.png",
    type: [
      {
        standard: {image: "mix_standard.png"},
        cupole: {image: "mix_base.png"},
        mosaique: {image: "mix.png"}
      }
    ],
    models: [
      {name: "MIX100", capacity: "4", production: "70"},
      {name: "MIX110", capacity: "5", production: "90"},
      {name: "MIX130", capacity: "8", production: "130"},
      {name: "MIX150", capacity: "10", production: "160"},
      {name: "MIX180", capacity: "15", production: "190"},
    ]
  },
  {
    id: 3,
    pdf: "MIXe.pdf",
    category: "statiques",
    title: "MIXe",
    shortDescription: "Four à bois dotée de tiroir pour la récupération des cendres et d'une grille de foyer.",
    four: "bois",
    sole: "gaz",
    price: "13.840,00",
    longDescription: "La série \"MIX\" est un four à bois dotée de tiroir pour la récupération des cendres et d'une grille de foyer. Un brûleur à gaz avec contrôle digitale assure le maintien en temperature de la sole. L'effet combiné des deux systèmes bois et gaz permet d'obtenir des prestations de cuisson parfaite avec des grandes charges de travail en autorisant des cuissons rapides et uniformes grâce à la stabilité thermique du four. Les fours \"MIX\" sont équipés avec nos brûleurs atmosphériques exclusifs qui sont exempts d'entretien, peuvent fonctionner au gaz naturel ou GPL. L'action des brûleurs de sole permet une économie du 50% de bois en comparaison à un four traditionnel. Les fours de la série \"MIX\" sont prévus pour être facilement convertis au gaz comme la série Morello \"FG\".",
    image: "mix.png",
    type: [
      {
        standard: {image: "mix_standard.png"},
        cupole: {image: "mix_base.png"},
        mosaique: {image: "mix.png"}
      }
    ],
    models: [
      {name: "MIXe100", capacity: "4", production: "70"},
      {name: "MIXe110", capacity: "5", production: "90"},
      {name: "MIXe130", capacity: "8", production: "130"},
      {name: "MIXe150", capacity: "10", production: "160"},
      {name: "MIXe180", capacity: "15", production: "190"},
    ]
  },
  {
    id: 4,
    pdf: "PG.pdf",
    category: "statiques",
    title: "PG",
    shortDescription: "Four à gaz, permet d'obtenir les mêmes caractéristiques de cuisson qu'un four à bois.",
    four: "gaz",
    sole: "",
    price: "9.871,00",
    longDescription: "La série \"PG\" le montage du brûleur à gaz a l'intérieur de la chambre de cuisson permet d'obtenir les mêmes caractéristiques de cuisson qu'un four à bois. Les fours \"PG\" équipés avec nos brûleurs atmosphériques exclusifs qui sont exempts d'entretien peuvent marcher au gaz naturel ou GPL. Le systéme de contrôle facile et intuitif du brûleur à gaz est du type digital avec modulateur de flamme et thermostat. Les fours de la série \"PG\" sont prévus pour être facilement convertis à bois comme la série Morello \"LP\". Le devant rustique en briques apparentes peut être livré avec façade de forme plate ou arrondie suivant la demande. Fours dotés de grande masse réfractaire qui produit un effet volant thermique important duquel en découle une économie d'energie et autorise des cuissons rapides et uniformes dues à la stabilité thermique du four.",
    image: "pg_cup_mos.png",
    type: [
      {
        standard: {image: "pg.png"},
        cupole: {image: "pg_cup.png"},
        mosaique: {image: "pg_cup_mos.png"}
      }
    ],
    models: [
      {name: "PG75", capacity: "2", production: "30"},
      {name: "PG100", capacity: "5", production: "60"},
      {name: "PG110", capacity: "6", production: "75"},
      {name: "PG130", capacity: "9", production: "110"},
      {name: "PG150", capacity: "12", production: "140"},
      {name: "PG180", capacity: "16", production: "180"},
    ]
  },
  {
    id: 5,
    pdf: "PGi.pdf",
    category: "statiques",
    title: "PGi",
    shortDescription: "Les fours PGi ont un fonctionnement hybride à gaz et bois, et peuvent marcher seulement à gaz, seulement à bois, ou avec les deux en même temps.",
    four: "gaz et bois",
    sole: "",
    price: "15.061,00",
    longDescription: "Les fours PGi ont un fonctionnement hybride à gaz et bois, et peuvent marcher seulement à gaz, seulement à bois, ou avec les deux en même temps. Les PGi à gaz, une fois qu'ils dépassent 200° C, entraînent la combustion spontanée du bois, fait que fort réduise leurs émissions de particules polluantes. Les fours PGi appartiennent à la famille des fours statiques, grâce à la stabilité thermique élevé du réfractaire et à la qualité des matériaux, sont capables de cuir toujours parfaitement, même en cas de cuissons nombreuses et rapprochées, sans que le plan se refroidisse, et sont parfaits pour cuire du pain et produits gastronomiques à flammes éteintes.",
    image: "pgi_cup_mos.png",
    type: [
      {
        standard: {image: "pgi.png"},
        cupole: {image: "pgi_cup.png"},
        mosaique: {image: "pgi_cup_mos.png"}
      }
    ],
    models: [
      {name: "PGi100", capacity: "6", production: "70"},
      {name: "PGi110", capacity: "7", production: "90"},
      {name: "PGi130", capacity: "9", production: "120"},
      {name: "PGi150", capacity: "14", production: "150"},
    ]
  },
  {
    id: 6,
    pdf: "FGi.pdf",
    category: "statiques",
    title: "FGi",
    shortDescription: "Les fours FGi ont un fonctionnement hybride à gaz et bois, et peuvent marcher seulement à gaz, seulement à bois, ou avec les deux en même temps.",
    four: "gaz et bois",
    sole: "gaz",
    price: "19.056,00",
    longDescription: "Les fours FGi ont un fonctionnement hybride à gaz et bois, et peuvent marcher seulement à gaz, seulement à bois, ou avec les deux en même temps. Les FGi à gaz, une fois qu'ils dépassent 200° C, entraînent la combustion spontanée du bois, fait que fort réduit leurs émissions de particules polluantes. Les fours de la ligne FGi sont équipés avec deux brûleurs à gaz indépendants, pour le chauffage de la voûte et du plan de cuisson respectivement. Les fours FGi appartient à la famille des fours statiques ; grâce à la stabilité thermique élevée du réfractaire et au chauffage indépendant du plan de cuisson, sont capables de cuir toujours parfaitement, même en cas de cuissons nombreuses et rapprochées, sans que le plan se refroidisse. La chambre de cuisson à plan elliptique a été conçue pour réduire la profondeur du four et simplifier son emploi, à surface utile égale, par rapport aux fours avec sole circulaire. Les brûleurs à gaz dans la chambre de cuisson, avec leurs flammes lentes, reproduisent l'aspect et les caractéristiques de cuisson du feu de bois.",
    image: "fgi.png",
    type: [
      {
        standard: {image: "fgi.png"},
        cupole: {image: "fgi_cup.png"},
        mosaique: {image: "fgi_mos.png"}
      }
    ],
    models: [
      {name: "FGi100", capacity: "6", production: "70"},
      {name: "FGi110", capacity: "7", production: "90"},
      {name: "FGi130", capacity: "9", production: "120"},
      {name: "FGi150", capacity: "14", production: "150"},
    ]
  },
  {
    id: 7,
    pdf: "FG.pdf",
    category: "statiques",
    title: "FG",
    shortDescription: "Les fours de la ligne FG sont équipés avec deux brûleurs à gaz indépendants, pour le chauffage de la voûte et du plan de cuisson respectivement.",
    four: "gaz et bois",
    sole: "gaz",
    price: "17.479,00",
    longDescription: "Les fours de la ligne FG sont équipés avec deux brûleurs à gaz indépendants, pour le chauffage de la voûte et du plan de cuisson respectivement. Les fours FG appartient à la famille des fours statiques ; grâce à la stabilité thermique élevée du réfractaire et au chauffage indépendant du plan de cuisson, sont capables de cuir toujours parfaitement, même en cas de cuissons nombreuses et rapprochées, sans que le plan se refroidisse. Les brûleurs à gaz dans la chambre de cuisson, avec leurs flammes lentes, reproduisent l’aspect et les caractéristiques de cuisson du feu de bois. Les brûleurs, en dessous du plan de cuisson, lui donnent stabilité thermique, pour des cuissons plus vites et homogènes. La grande masse réfractaire et l’isolation ont été conçus au bout d’élever le rendement thermique et diminuer les frais d’exploitation.",
    image: "fg.png",
    type: [
      {
        standard: {image: "fg.png"},
        cupole: {image: "fg_cup.png"},
        mosaique: {image: "fg_cup_mos.png"}
      }
    ],
    models: [
      {name: "FG100", capacity: "5", production: "75"},
      {name: "FG110", capacity: "6", production: "90"},
      {name: "FG130", capacity: "9", production: "130"},
      {name: "FG150", capacity: "12", production: "160"},
    ]
  },
  {
    id: 8,
    pdf: "FM.pdf",
    category: "statiques",
    title: "FM",
    shortDescription: "Les fours FM ont un fonctionnement à bois principalement, et système auxiliaire de chauffage sole à gaz, ils peuvent marcher seulement à bois, ou à bois + gaz en même temps.",
    four: "bois",
    sole: "gaz",
    price: "23.043,00",
    longDescription: "Les fours FM appartiennent à l'ensemble des fours statiques, avec la stabilité thermique élevée du réfractaire sont parfaits pour cuire du pain et produits gastronomiques à flammes éteintes. Le tiroir extérieur de chute et ramassage cendre facilite le nettoyage de la sole, améliore la combustion du bois et la propreté des cuissons. Chauffage indépendant de la sole avec contrôle thermostatique de la température. La grande masse réfractaire et l'isolation ont été conçues au but d'élever le rendement thermique et diminuer les frais d'exploitation et la perte de chaleur. La chaleur produite pour chauffer la sole est réintroduite dans la chambre de cuisson et utilisée une fois plus au but d'économiser la consommation du four.",
    image: "fm_cup_mos.png",
    type: [
      {
        standard: {image: "fm.png"},
        cupole: {image: "fm_cup.png"},
        mosaique: {image: "fm_cup_mos.png"}
      }
    ],
    models: [
      {name: "FM110", capacity: "5", production: "75"},
      {name: "FM130", capacity: "8", production: "110"},
      {name: "FM150", capacity: "10", production: "140"},
      {name: "FM180", capacity: "15", production: "180"},
    ]
  },
  {
    id: 9,
    pdf: "PAX.pdf",
    category: "statiques",
    title: "PAX",
    shortDescription: "Four à bois ou gaz fabriqué en matériaux réfractaires vibrés, à haute teneur d'alumine garanti 10 ans.",
    four: "bois ou gaz",
    sole: "",
    price: "5.268,00",
    longDescription: "Série \"PAX\" four à bois ou gaz fabriqué en matériaux réfractaires vibrés, à haute teneur d'alumine garanti 10 ans, disponible en sept dimentions differentes. La série \"PAX\" peut être dotée du système de chauffage avec des brûleurs à gaz tendu ou à torche du type atmosphérique exempte d'entretien pouvant fonctionner au gaz naturel ou GPL. Coupole monolitique en matériaux réfractaires pressé et vibré autour d'un grillage métallique pour favoriser le transport, l'assemblage, et le renversement laterale pour lui permettre d'emprinter des passages de 60 cm et faire la mise en place avec un transpallette.",
    image: "pax.png",
    type: [
      {
        standard: {},
        cupole: {},
        mosaique: {}
      }
    ],
    models: [
      // {name: "90", capacity: "5", production: "75"},
      // {name: "100", capacity: "5", production: "75"},
      // {name: "110", capacity: "6", production: "90"},
      // {name: "120", capacity: "9", production: "130"},
      // {name: "130", capacity: "9", production: "130"},
      // {name: "140", capacity: "9", production: "130"},
      // {name: "150", capacity: "12", production: "160"},
    ]
  },
  {
    id: 10,
    pdf: "FRV.pdf",
    category: "rotatif",
    title: "FRV Evento",
    shortDescription: "Four électrique tournant à convection en rèfractaire avec systéme de chauffage indépendent.",
    four: "électriques",
    sole: "électriques",
    price: "14.989,00",
    longDescription: "Four électrique tournant à convection en rèfractaire avec systéme de chauffage indépendent entre voute et sole. Il permet differentes cuissons: pizza en poêle ou à la plaque, pain, gastronomie et patisserie grâce à la régulation du flux d'air et de la température de la sole de cuisson. Disponible avec panneau de commande Touch System à technologie PID (Proportionnel integratif derivatif) complètement programmable ou avec panneau de commande et gestion de la puissance programmable avec technologie SSR(Solid State Relais). Une porte basculante avec double verre et prises ergonomiques d'utilisation facile à eté dessinée en détail. Grande économie énergétique due à la considèrable masse réfractaire de 500 Kg. Revêtement intèrieur de la chambre de cuisson en acier inox. Sole de cuisson tournante en matériel réfractaire, programmable avec temps de cuisson et avertisseur.",
    image: "frv.png",
    type: [
      {
        standard: {image: "frv.png"},
        cupole: {image: "frv_cup.png"},
        mosaique: {image: "frv_cup_mos.png"}
      }
    ],
    models: [
      {name: "FVR100", capacity: "6", production: "90"},
      {name: "FVR125", capacity: "9", production: "160"},
    ]
  },
  {
    id: 11,
    pdf: "FGR.pdf",
    category: "rotatif",
    title: "FGR",
    shortDescription: "Four tournant pour pizza fonctionnant au gaz avec système de chauffage thermostatique à double brûleurs.",
    four: "gaz",
    sole: "gaz",
    price: "23.685,00",
    longDescription: "Four tournant pour pizza fonctionnant au gaz avec système de chauffage thermostatique à double brûleurs avec contrôle digital indépendant entre voute et sole, disponible en trois dimensions. La rotation de la sole est gérée par un système programmable. Four doté d'excellente homogénéité et haute qualité de cuisson due a l'action combiné de la sole et son effet volant thermique, le système à double brûleurs avec tiroir pour le ramassage des cendres, garanti une grande performance de production et une meilleure hygiène. Les fours \"FGR\" sont équipés avec nos brûleurs atmosphériques exclusifs exempt d'entretien pouvant fonctionner au gaz naturel ou GPL. L'isolation thermique réalisés en fibre minérale à haut pouvoir isolant et structure en matériaux réfractaires.",
    image: "fgr.png",
    type: [
      {
        standard: {image: "fgr.png"},
        cupole: {image: "fgr_cup.png"},
        mosaique: {image: "fgr_cup_mos.png"}
      }
    ],
    models: [
      {name: "FGR100", capacity: "6", production: "100"},
      {name: "FGR110", capacity: "7", production: "120"},
      {name: "FGR130", capacity: "10", production: "170"},
      {name: "FGR150", capacity: "14", production: "240"},
      {name: "FGR160", capacity: "16", production: "290"},
    ]
  },
  // {
  //   id: 12,
  //   category: "rotatif",
  //   title: "FWR",
  //   shortDescription: "Les fours FW sont fours à bois traditionnels avec plan de cuisson rotatif.",
  //   four: "bois",
  //   sole: "",
  //   price: "00,00",
  //   longDescription: "Les fours FWR sont fours à bois traditionnels avec plan de cuisson rotatif. Les FWR appartiennent à la famille des fours rotatifs, ou le plan de cuisson, motorisé à vitesse et direction variables, donne uniformité à la cuisson et en raccourcit les temps. La zone de combustion du bois est séparée du plan rotatif par un pare-flammes métallique. Le tiroir extérieur de chute et ramassage cendre facilite le nettoyage de la sole, améliore la combustion du bois et la propreté des cuissons. La grande masse réfractaire et l'isolation ont été conçues au but d'élever le rendement thermique et diminuer les frais d'exploitation et la perte de chaleur. Système breveté de nettoyage automatique des déchets autour de la sole tournante.",
  //   image: "fvr.png",
  //   type: [
  //     {
  //       standard: {image: "fvr.png"},
  //       cupole: {image: "fvr_cup.png"},
  //       mosaique: {image: "fvr_cup_mos.png"}
  //     }
  //   ],
  //   models: [
  //     {name: "FVR100", capacity: "6", production: "90"},
  //     {name: "FVR125", capacity: "9", production: "160"},
  //   ]
  // },
  {
    id: 13,
    pdf: "FMRe.pdf",
    category: "rotatif",
    title: "FMRe",
    shortDescription: "Les fours à pizza de la ligne FMRe sont des fours à bois rotatifs équipés avec un système électrique sous la sole qui maintient la sole en température et réduit l'attention de l'utilisateur",
    four: "bois",
    sole: "électriques",
    price: "23.043,00",
    longDescription: "Les fours FMRe ont un fonctionnement à bois principalement, et système auxiliaire électrique de chauffage sole rotative. Les FMR/E appartiennent à la famille des fours rotatifs, ou le plan de cuisson, motorisé à vitesse et direction variables, donne uniformité à la cuisson et en raccourcit les temps. Les éléments chauffants en dessous du plan de cuisson rotatif, lui donnent stabilité thermique, pour des cuissons plus vites et homogènes. La zone de combustion du bois est séparée du plan rotatif par un pare-flammes métallique. Le tiroir extérieur de chute et ramassage cendre facilite le nettoyage de la sole, améliore la combustion du bois et la propreté des cuissons. Chauffage indépendant de la sole avec contrôle thermostatique de la température. La grande masse réfractaire et l'isolation ont été conçues au but d'élever le rendement thermique et diminuer les frais d'exploitation et la perte de chaleur. La chaleur produite pour chauffer la sole est réintroduite dans la chambre de cuisson et utilisée une fois plus au but d'économiser la consommation du four. La puissance électrique des éléments, grâce au système de contrôle, est réglée automatiquement en intensité selon la température programmée, au but d'économiser la consommation et de simplifier l'emploi.",
    image: "fmre.png",
    type: [
      {
        standard: {},
        cupole: {},
        mosaique: {}
      }
    ],
    models: [
      {name: "FMRe100", capacity: "4", production: "70"},
      {name: "FMRe110", capacity: "5", production: "90"},
      {name: "FMRe130", capacity: "8", production: "120"},
      {name: "FMRe150", capacity: "10", production: "160"},
    ]
  },
  {
    id: 14,
    pdf: "MR.pdf",
    category: "rotatif",
    title: "MR",
    shortDescription: "Les MR sont fours à bois, avec sole rotative chauffée par brûleurs à gaz. Les fours MR appartient à la famille des fours rotatifs, ou le plan de cuisson, motorisé à vitesse et direction variables, donne uniformité à la cuisson et en raccourcit les temps.",
    four: "bois",
    sole: "gaz",
    price: "23.542,00",
    longDescription: "Les fours MR sont des fours à double système de chauffage indépendant entre la voûte et la sole. Chauffage au bois de la voute et chauffage sous la sole au gaz. Il sont équipés de 2 bouches- La zone de combustion du bois est séparée du plan rotatif par un pare-flammes métallique, et accessible par porte à côté de la bouche du four (de série à gauche, en option à droite).",
    image: "mr.png",
    type: [
      {
        standard: {},
        cupole: {},
        mosaique: {}
      }
    ],
    models: [
      {name: "MR110", capacity: "7", production: "120"},
      {name: "MR130", capacity: "10", production: "170"},
      {name: "MR150", capacity: "14", production: "240"},
    ]
  },
  {
    id: 14,
    pdf: "MRe.pdf",
    category: "rotatif",
    title: "MRe",
    shortDescription: "Les MRe sont fours à bois, avec sole rotative chauffée par résistances électriques. Les fours MRE appartient à la famille des fours rotatifs, ou le plan de cuisson, motorisé à vitesse et direction variables, donne uniformité à la cuisson et en raccourcit les temps.",
    four: "bois",
    sole: "électriques",
    price: "23.542,00",
    longDescription: "Les MRe sont fours à bois, avec sole rotative chauffée par résistances électriques. Les fours MRE appartient à la famille des fours rotatifs, ou le plan de cuisson, motorisé à vitesse et direction variables, donne uniformité à la cuisson et en raccourcit les temps. Les éléments chauffants, en dessous du plan de cuisson rotatif, lui donnent stabilité thermique, pour des cuissons plus vites et homogènes. La zone de combustion du bois est séparée du plan rotatif par un pare-flammes métallique, et accessible par porte à côté de la bouche du four (de série à gauche, en option à droite). Le tiroir extérieur de chute et ramassage cendre facilite le nettoyage de la sole, améliore la combustion du bois et la propreté des cuissons. La grande masse réfractaire et l'isolation ont été conçues au bout d'élever le rendement thermique et diminuer les frais d'exploitation.",
    image: "mre.png",
    type: [
      {
        standard: {},
        cupole: {},
        mosaique: {}
      }
    ],
    models: [
      {name: "MRe110", capacity: "7", production: "120"},
      {name: "MRe130", capacity: "10", production: "170"},
      {name: "MRe150", capacity: "14", production: "240"},
    ]
  },
  {
    id: 15,
    pdf: "MRi.pdf",
    category: "rotatif",
    title: "MRi",
    shortDescription: "Les fours MRi ont un fonctionnement hybride à gaz et bois, et peuvent marcher seulement à gaz, seulement à bois, ou avec les deux en même temps.",
    four: "gaz et bois",
    sole: "gaz",
    price: "26.511,00",
    longDescription: "Les fours MRi ont un fonctionnement hybride à gaz et bois, et peuvent fonctionner seulement à gaz, seulement à bois, ou avec les deux en même temps. La zone de combustion du bois est séparée du plan rotatif par un pare-flammes métallique, et accessible par porte à côté de la bouche du four (de série à gauche, en option à droite).",
    image: "mri.png",
    type: [
      {
        standard: {},
        cupole: {},
        mosaique: {}
      }
    ],
    models: [
      {name: "MRi110", capacity: "7", production: "120"},
      {name: "MRi130", capacity: "10", production: "170"},
      {name: "MRi150", capacity: "14", production: "240"},
    ]
  },
  {
    id: 15,
    pdf: "FGRi.pdf",
    category: "rotatif",
    title: "FGRi",
    shortDescription: "Les fours FGRi ont un fonctionnement hybride à gaz et bois, et peuvent marcher seulement à gaz, seulement à bois, ou avec les deux en même temps.",
    four: "gaz et bois",
    sole: "gaz",
    price: "24.632,00",
    longDescription: "Les fours FGRi ont un fonctionnement hybride à gaz et bois, et peuvent marcher seulement à gaz, seulement à bois, ou avec les deux en même temps. Les FGRi à gaz, une fois qu'ils dépassent 200° C, entraînent la combustion spontanée du bois, fait que fort réduise les émissions de particules polluantes. Les fours de la ligne FGRi sont équipés des deux brûleurs à gaz indépendants, pour le chauffage de la voûte et du plan de cuisson rotatif respectivement, grâce à la stabilité thermique élevée du réfractaire et au chauffage indépendant du plan de cuisson, sont capables de cuir toujours parfaitement, même en cas de cuissons nombreuses et rapprochées, sans que le plan se refroidisse. Les fours FGRi appartient à la famille des fours rotatifs, ou le plan de cuisson, motorisé à vitesse et direction variables, donne uniformité à la cuisson et en raccourcit les temps. La chambre de cuisson à plan elliptique a été conçue pour réduire la profondeur du four et simplifier son emploi, à surface utile égale, par rapport aux fours à sole circulaire. Les brûleurs à gaz dans la chambre de cuisson, avec leurs flammes lentes, reproduisent l'aspect et les caractéristiques de cuisson du feu de bois. Les zones de combustion du bois et des brûleurs à gaz sont séparées par des chenets métalliques. Le tiroir extérieur de chute et ramassage cendre facilite le nettoyage de la sole, améliore la combustion du bois et la propreté des cuissons. Les brûleurs, en dessous du plan de cuisson rotatif, lui donnent stabilité thermique, pour des cuissons plus vites et homogènes. La grande masse réfractaire et l'isolation ont été conçues au bout d'élever le rendement thermique et diminuer les frais d'exploitation. Double mode de fonctionnement : manuel – l'utilisateur choisit la température de cuisson et règle l'intensité de la flamme avec son modulateur.",
    image: "fgri.png",
    type: [
      {
        standard: {image: "fgri.png"},
        cupole: {image: "fgri_cup.png"},
        mosaique: {image: "fgri_cup_mos.png"}
      }
    ],
    models: [
      {name: "FGRi100", capacity: "6", production: "100"},
      {name: "FGRi110", capacity: "8", production: "140"},
      {name: "FGRi130", capacity: "10", production: "140"},
      {name: "FGRi150", capacity: "14", production: "240"},
    ]
  },
];
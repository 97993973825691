import React, { useEffect } from 'react';
import { Loader } from '../helpers/Loader/Loader';
// import {Separator} from './../components/Separator';
import {ContactFooter} from "./../components/ContactFooter";
import { Helmet, HelmetData } from 'react-helmet-async';
import './../App.css';

export const Contacts = () => {
  
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const helmetData = new HelmetData({});

  return (
    <React.Fragment>
      <Helmet helmetData={helmetData} >
        <title>Contact - Fours Demat</title>
        <meta name="description" content="Contactez-nous pour découvrir notre gamme de fours à pizza" />
        <meta property="og:title" content="Fours Demat - Contact"/>
        <meta property="og:description" content="Contactez-nous pour découvrir notre gamme de fours à pizza" />
        <meta property="og:type" content="article" />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:image" content={require('./../images/logo-small.png')} />
        <meta property="og:site_name" content="Fours Demat" />
        <meta property="og:section" content="Contact" />
        <link rel="canonical" href="https://www.foursdemat.com/contact" />
      </Helmet>
      <Loader />
      <ContactFooter />
    </React.Fragment>
  );
};
import React from "react";
import {NavLink} from 'react-router-dom';

export const Footer = () => {
  const year = new Date().getFullYear();
  return (
    <div className="containerFooter"> 
      <div className="container">
        <div className="row">
          <div className="footer">
            <div>
              <NavLink to='/accueil'>
                <img style={{width: 150}} src={require('./../images/logo-small.png')} alt="Logo Sarl Demat" />
              </NavLink>
              <span>Copyright © {year} - Tous droits réservés</span>
            </div>
            <div>
              <NavLink className={({ isActive }) => isActive ? 'active' : ''}  to='/terms-conditions'>Mentions légales et politique de confidentialité</NavLink>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
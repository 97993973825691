import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { fours } from "../articles/fours";
import { Loader } from '../helpers/Loader/Loader';
import { Separator } from './../components/Separator';
import { Helmet, HelmetData } from 'react-helmet-async';
import './../App.css';
import { Fours } from '../components/Fours';

export const FourMorelloDetails = (p) => {

  const { title } = useParams();
  const result = fours.filter(four => four.title === title);
  const id = result[0]?.id;
  const [imgSelected, setImgSelected] = useState();

  useEffect(() => {
    window.scrollTo(0, 0);
  });

  const helmetData = new HelmetData({});

  return (
    <React.Fragment>
      <Helmet helmetData={helmetData} >
        <title>{`Four à pizza Morello Forni - Ligne ${result[0]?.title === "LP" ? "L/LP" : result[0]?.title} - Fours Demat`}</title>
        <meta name="description" content={result[0]?.shortDescription} />
        <meta property="og:title" content={`Morello Forni ligne ${result[0]?.title === "LP" ? "L/LP" : result[0]?.title} four à pizza et équipements`} />
        <meta property="og:description" content={result[0]?.shortDescription} />
        <meta property="og:type" content="article" />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:image" content={`https://www.foursdemat.com/fours-a-pizza/${result[0]?.image}`} />
        <meta property="og:site_name" content="Fours Demat" />
        <meta property="og:section" content="Fours à pizza" />
        <link rel="canonical" href={`https://www.foursdemat.com/fours-a-pizza-morello-forni/${result[0]?.title}`} />
      </Helmet>
      <Loader />
      {/* <Separator /> */}
      <div className='foursMorello' style={{ marginTop: 40 }}>
        <div className='container'>
          <div className='row'>
            <div className='col-sm-12 col-md-12 col-lg-12 col-xl-12'>
              <div className='contBoxDetails'>
                <div className='row'>
                  <div className='col-sm-12 col-md-6 col-lg-4 col-xl-4 fourDetails'>
                    <div className='image'>
                      {imgSelected !== undefined ?
                        <img src={require('./../images/forni/' + imgSelected)} alt={result[0]?.title} />
                        :
                        <img src={require('./../images/forni/' + result[0]?.image)} alt={result[0]?.title} />
                      }
                    </div>
                    <div className='allestimenti'>
                      {result[0]?.type[0]?.standard?.image !== undefined &&
                        <div className='boxAllestimenti'>
                          <div className='image'>
                            <img onClick={() => setImgSelected(result[0]?.type[0]?.standard.image)} src={require('./../images/forni/' + result[0]?.type[0]?.standard.image)} alt="logo morello forni" />
                          </div>
                          <div className='nomeAllestimento'>
                            <span>Standard</span>
                          </div>
                        </div>
                      }
                      {result[0]?.type[0]?.cupole?.image !== undefined &&
                        <div className='boxAllestimenti'>
                          <div className='image'>
                            <img onClick={() => setImgSelected(result[0]?.type[0]?.cupole.image)} src={require('./../images/forni/' + result[0]?.type[0]?.cupole.image)} alt="logo morello forni" />
                          </div>
                          <div className='nomeAllestimento'>
                            <span>Cupole Base</span>
                          </div>
                        </div>
                      }
                      {result[0]?.type[0]?.mosaique?.image !== undefined &&
                        <div className='boxAllestimenti'>
                          <div className='image'>
                            <img onClick={() => setImgSelected(result[0]?.type[0]?.mosaique.image)} src={require('./../images/forni/' + result[0]?.type[0]?.mosaique.image)} alt="logo morello forni" />
                          </div>
                          <div className='nomeAllestimento'>
                            <span>Cupole Mosaïque</span>
                          </div>
                        </div>
                      }
                    </div>
                  </div>
                  <div className='col-sm-12 col-md-6 col-lg-8 col-xl-8 fourDetails'>
                    <div className='navigationBar' style={{ marginBottom: 10 }}>
                      <a href={"/fours-a-pizza-morello-forni"}>Fours à pizza / </a>
                      <span>Morello Forni - Ligne {result[0]?.title === "LP" ? "L/LP" : result[0]?.title}</span>
                    </div>
                    <div className="title">
                      <span>Four à pizzas <b>{result[0]?.title === "LP" ? "L/LP" : result[0]?.title}</b></span>
                    </div>
                    <div className='description' dangerouslySetInnerHTML={{ __html: result[0]?.longDescription.split(".").join(".<br>") }}></div>
                    <div className="price">
                      <span style={{ fontSize: 13 }}>À partir de</span>
                      <span> {result[0]?.price} €</span>
                    </div>
                    {result[0]?.models.length > 0 &&
                      <div className='infoDetails'>
                        <div className='header'>
                          <div>Modèle</div>
                          <div>Capacité</div>
                          <div>Prod. à l'heure</div>
                        </div>
                        <div className='body'>
                          {result[0]?.models.map((model, i) => {
                            return (
                              <div className='rowInfoDetails' key={i}>
                                <div>{model.name}</div>
                                <div>{model.capacity} pizze</div>
                                <div>{model.production} pizze / h</div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    }
                    <div className='documentation'>
                      <div>
                        <a
                          target={"blank"}
                          href={require('../pdf/' + result[0]?.pdf)}
                          title={`Documentation Technique ${result[0]?.title}`}
                        >
                          <span>Télécharger informations techniques</span>
                          <span>
                            <svg xmlns="http://www.w3.org/2000/svg" width="10" height="13" fill="none"><path fill="#2d4fb3" d="M.5 12h9a.5.5 0 0 1 .09.992L9.5 13h-9a.5.5 0 0 1-.09-.992L.5 12h9-9ZM4.91.008 5 0a.5.5 0 0 1 .492.41L5.5.5v8.792l2.682-2.681a.5.5 0 0 1 .638-.058l.07.058a.5.5 0 0 1 .057.638l-.058.069-3.535 3.536a.5.5 0 0 1-.638.057l-.07-.057-3.535-3.536a.5.5 0 0 1 .638-.765l.069.058L4.5 9.292V.5a.5.5 0 0 1 .41-.492L5 0l-.09.008Z"></path></svg>
                          </span>
                        </a>
                      </div>
                    </div>
                    <div className='contactezNous'>
                      <a
                        href={'/contact'}
                        title="Contactez-nous et nous serons heureux de vous fournir plus d'informations!"
                      >
                        Contactez-nous et nous serons heureux de vous fournir plus d'informations!
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div style={{ height: 40 }}></div>
        <Fours category={result[0]?.category} articles={4} id={id} viewTag={true} />
      </div>
    </React.Fragment>
  );
};
import React from "react";

export const ThirdParty = () => {
  return (
    <div className="contPrivacyMarketing">
      <p>
      que mes données à caractère personnel soient communiquées aux filiales et sociétés affiliées de  <span style={{fontWeight: 500}}>Sarl Demat</span>, lesquels les traiteront à des fins de marketing comme indiqué au point "e" de la note d'information, sous format papier, automatisé ou électronique, et notamment par courrier postal ou électronique, par téléphone (par exemple : automates d'appel, SMS, MMS), par télécopie et par tout autre moyen (par exemple : sites web, applications mobiles).
      </p>
    </div>
  );
};
import React, { useEffect, useState } from "react";
import './Loader.scss';

export const Loader = () => {

  const [loader, setLoader] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setLoader(false);
    }, 700);
  }, []);

  return (
    <div className={`loader ${loader ? "visible" : "hide"}`}>
      <div><img style={{width: 200, marginBottom: 20}} src={require('./../../images/logo-small.png')} alt="Logo" /></div>
      <div className="lds-dual-ring"></div>
    </div>
  );
};